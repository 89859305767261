import { useEffect, useMemo, useRef, useState } from 'react';
import { Menu } from '@headlessui/react';

import PopoverWithFloat from '../hoc/PopoverWithFloat';
import useClickOutside from '../../state/hooks/useClickOutside';


function TimePickerInput({ value = '', use24Hour = false, className = '', minTime = "00:00", maxTime = "23:59", minuitStep = 5, onChange, onBlur, onFocus }: Props) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [focused, setFocused] = useState(false);

    const [meridiem, setMeridiem] = useState<Meridian>("AM");
    const [timeValue, setTimeValue] = useState<string>(value || '');

    let isClicked = false;
    useClickOutside(containerRef, () => {
        setFocused(false);
        if (onBlur && isClicked) {
            onBlur();
        }
    });


    useEffect(() => {
        const meridian = getAmPm(value || '');
        setMeridiem(meridian);
        setTimeValue(value || '');
    }, [value]);


    const timeList = useMemo(() => generateTimeIntervals(minTime, maxTime, minuitStep), [minTime, maxTime, minuitStep]);
    const groupedFilteredTimeList = useMemo(() => {
        return (use24Hour ? timeList : timeList.filter(item => getAmPm(item) === meridiem))
            .reduce((groups: { [index: string]: string[] }, time: string) => {
                const hour = time.split(":")[0]; // Extract the hour part
                if (!groups[hour]) {
                    groups[hour] = []; // Initialize the group if it doesn't exist
                }
                groups[hour].push(time); // Add the time to the appropriate group
                return groups;
            }, {});
    }, [use24Hour, timeList, meridiem]);


    return (
        <div ref={containerRef} onClick={() => isClicked = true}>
            <Menu as="div" className="relative" onFocus={onFocus}>
                {
                    ({ open }) => (
                        <PopoverWithFloat placement='bottom-start'>
                            <Menu.Button type='button' className={`w-full flex items-center text-left bg-transparent autofill:bg-white border border-gray-300 dark:border-gray-700 hover:border-blue-300 dark:hover:border-gray-600 ${focused || open ? '!border-blue-500/60 !dark:border-blue-500/60' : ''} focus:outline-none rounded-lg py-2.5 px-4 ${className}`}>
                                <span className='flex-grow mr-3.5'>
                                    {use24Hour ? timeValue : convertTo12HourFormat(timeValue)}
                                </span>
                                <i className="ri-time-line opacity-60"></i>
                            </Menu.Button>

                            <Menu.Items as='div' unmount={false} className="w-48 -left-2.5 origin-top-left rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden py-3">
                                <div className='max-h-64 flex items-start gap-x-5 overflow-y-auto py-3 px-5'>
                                    {
                                        !use24Hour &&
                                        <div className='sticky top-0 text-left text-sm'>
                                            {
                                                ['AM', 'PM'].map(item =>
                                                    <button
                                                        key={item}
                                                        type='button'
                                                        onClick={() => {
                                                            const [time] = timeValue.split(' ');
                                                            const realTime = convertTo24HourFormat(`${time} ${item}`);

                                                            //setMeridiem(item as any);
                                                            if (onChange) {
                                                                onChange(realTime);
                                                            } else {
                                                                setTimeValue(realTime);
                                                            }
                                                        }}
                                                        className={`rounded-md ${item === meridiem ? 'bg-blue/25 hocus:bg-blue/40' : 'hocus:bg-gray-100 dark:hover:bg-gray-600 text-gray-500 dark:text-gray-300'} px-2 py-1 mb-2`}
                                                    >
                                                        {item}
                                                    </button>
                                                )
                                            }
                                        </div>
                                    }
                                    <div className='divide-y divide-gray-200 dark:divide-gray-700'>
                                        {
                                            Object.keys(groupedFilteredTimeList)
                                                .sort((a, b) => Number(a) - Number(b))
                                                .map((hour) => {
                                                    const hourGroup = groupedFilteredTimeList[hour];

                                                    return (
                                                        <div key={`${meridiem}-${hour}`} className="py-2">
                                                            {
                                                                hourGroup.map((timeItem) =>
                                                                    <Menu.Item
                                                                        key={`time-${timeItem}`}
                                                                        as="button" type='button'
                                                                        onClick={() => {
                                                                            if (onChange) {
                                                                                onChange(timeItem);
                                                                            } else {
                                                                                setTimeValue(value || '');
                                                                            }
                                                                        }}
                                                                        className={`w-full text-left ${timeItem === timeValue ? 'text-blue hocus:to-blue-600' : 'hocus:text-blue'} my-1`}
                                                                    >
                                                                        {use24Hour ? timeItem : convertTo12HourFormat(timeItem)}
                                                                    </Menu.Item>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                            </Menu.Items>
                        </PopoverWithFloat>
                    )
                }
            </Menu>
        </div>
    );
}


interface Props {
    value?: string | null;
    minuitStep?: number;
    use24Hour?: boolean;
    className?: string;
    minTime?: string;
    maxTime?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
}


function generateTimeIntervals(start: string = "00:00", end: string = "23:59", interval: number = 5): string[] {
    const times = [];

    // Convert start and end to minutes
    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;

    for (let i = startMinutes; i <= endMinutes; i += interval) {
        const hour = Math.floor(i / 60);
        const minute = i % 60;
        const formattedTime = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
        times.push(formattedTime);
    }

    return times;
}



function convertTo24HourFormat(time: string): string {
    const [timePart, meridian] = time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (meridian.toLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
    }
    if (meridian.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}



function convertTo12HourFormat(time: string): string {
    const timeParts = time.split(':');

    if (timeParts.length > 1) {
        let [hours, minutes] = timeParts.map(Number);

        const meridian = hours >= 12 ? 'PM' : 'AM';

        if (hours === 0) {
            hours = 12; // Midnight
        } else if (hours > 12) {
            hours -= 12; // Convert to 12-hour format
        }


        return `${hours}:${minutes.toString().padStart(2, '0')} ${meridian}`;
    }

    return '';
}


function getAmPm(time: string): Meridian {
    const [hours] = time.split(":").map(Number);
    return hours >= 12 ? "PM" : "AM";
}


type Meridian = "AM" | "PM";

export default TimePickerInput;